<template>
  <div>
    <div class="link-container-box ma-auto">
      <vue-qr-code v-if="qrCodeUrl != ''" class="qrCode" :text="qrCodeUrl" :size="200"></vue-qr-code>

      <v-row class="py-4">
        <v-col cols="12" class="hidden-sm-and-up">
          <app-button :title="$t(`label.download`)" customClass="buttonPrimary white--text body-2 mt-0" :action="downloadQrCodeImage"></app-button>
        </v-col>
        <v-col cols="12" sm="5" class="grow">
          <app-form-field v-model.trim="selectedPage" :label="$t(`field.referralPage`)" type="select" :items="pageList" :change="this.changeLinkByPage"></app-form-field>
        </v-col>
        <v-col cols="12" sm="4" class="grow">
          <app-form-field v-model.trim="selectedMode" :label="$t(`field.referralMode`)" type="select" :items="affilateLinkMode" :change="this.changeLinkByMode"></app-form-field>
        </v-col>
        <v-col cols="3" class="hidden-xs-only">
          <app-button :title="$t(`label.download`)" customClass="buttonPrimary white--text body-2 mt-0" :action="downloadQrCodeImage"></app-button>
        </v-col>
        <v-col cols="12" class="py-4">
          <app-form-field v-model.trim="qrCodeUrl" :label="$t(`field.referralLink`)" :class="'asdasd'" :readonly="true">
            <template v-slot:form-field-append-outer>
              <app-button :title="$t(`label.copy`)" customClass="buttonPrimary white--text body-2 mt-0" :action="copyReferralLink"></app-button>
            </template>
          </app-form-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import VueQrCode from 'vue-qrcode-component'
import { AFFILIATE_QUICK_LINK } from '@/store/affiliate.module'
import { uiHelper, locale } from '@/util'
export default {
  name: 'affiliateLink',
  components: {
    VueQrCode
  },
  created() {
    this.generateAffilateLinks()
  },
  data: () => ({
    qrCodeUrl: '',
    selectedPage: '',
    selectedMode: 1,
    pageList: [],
    affilateLinkMode: [
      {
        value: 1,
        displayText: locale.getMessage('affiliateLinkMode.1')
      },
      {
        value: 2,
        displayText: locale.getMessage('affiliateLinkMode.2')
      }
    ]
  }),
  computed: {
    affiliateLink() {
      return this.$store.state.affiliate.referralLink
    }
  },
  watch: {
    affiliateLink() {
      this.qrCodeUrl = this.$store.state.affiliate.referralLink[0].link
      this.populatePageList()
    }
  },
  methods: {
    changeLinkByMode() {
      this.generateAffilateLinks()
    },
    changeLinkByPage() {
      this.qrCodeUrl = this.affiliateLink.find(x => x.value == this.selectedPage).link
      try {
        this.$ga.event('Affiliate', `Generate Link`, `${this.selectedPage} Page`)
      } catch (err) {
        console.log(err)
      }
    },
    populatePageList() {
      let p = []
      this.affiliateLink.forEach(link => {
        p.push({
          value: link.value,
          displayText: locale.getMessage(`affiliateLinkPage.${link.value}`)
        })
      })
      this.pageList = p
      this.selectedPage = p[0].value
    },
    generateAffilateLinks() {
      let obj = {
        domain: uiHelper.getHostname(),
        mode: this.selectedMode,
        language: uiHelper.getLanguage()
      }

      this.$store.dispatch(`${AFFILIATE_QUICK_LINK}`, { obj })
    },
    copyReferralLink() {
      try {
        this.$ga.event('Affiliate', `Copy Link`, `${this.selectedPage} Page`)
      } catch (err) {
        console.log(err)
      }

      this.$copyText(this.qrCodeUrl)
      let dialog = this.$parent.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.copyReferralLink`)
      dialog.message.push(locale.getMessage(`message.copyReferralLinkSuccessful`))

      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: this.$parent.$parent.$parent.closePageDialog
      })
      this.$parent.$parent.$parent.openPageDialog(dialog)
    },
    downloadQrCodeImage() {
      try {
        this.$ga.event('Affiliate', `Download QR Code Link`, `${this.selectedPage} Page`)
      } catch (err) {
        console.log(err)
      }

      var link = document.createElement('a')
      link.download = 'JAYA9_referralQRCode.png'
      link.href = document.querySelector('.qrCode canvas').toDataURL()
      link.click()
    }
  }
}
</script>

<style lang="scss">
.link-container-box {
  max-width: 700px;
}

.qrCode {
  img {
    margin: auto;
  }
}

@media (max-width: 1263px) {
  .link-container-box {
    width: 90%;
  }
}
</style>
